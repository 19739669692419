@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import "~bootstrap-icons/font/bootstrap-icons.css";

html,
body,
#root,
.app-container {
  height: 100%;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.myNavbar {
  box-shadow: 1px 2px 23px 10px rgba(184, 184, 184, 0.37);
  -webkit-box-shadow: 1px 2px 23px 10px rgba(184, 184, 184, 0.37);
  -moz-box-shadow: 1px 2px 23px 10px rgba(184, 184, 184, 0.37);
  position: sticky;
  top: 0;
  z-index: 9999;
}

.form-control::placeholder {
  color: gray !important;
}

.hover-product:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}

.container-hotline {
  top: 0;
  z-index: 300;
  position: fixed;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  place-items: center; /* Memposisikan kotak di tengah halaman */
  height: fit-content; /* Sesuaikan dengan tinggi teks */
  background-color: #00b1bb;
  padding: 5px;
  overflow: hidden; /* Mencegah teks keluar dari kotak */
}

.moving-text {
  animation: moveLeftToRight 10s linear infinite; /* Animasi dari kiri ke kanan */
  color: white;
  white-space: nowrap; /* Mencegah teks pindah ke baris berikutnya */
}

@keyframes moveLeftToRight {
  0% {
    transform: translateX(100%); /* Mulai dari luar kotak sebelah kanan */
  }
  100% {
    transform: translateX(-100%); /* Berakhir di luar kotak sebelah kiri */
  }
}

.menuMobile {
  z-index: 999;
  position: fixed;
  top: 8px;
  right: 5px;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);
  border: 1px solid rgba(255, 255, 255, 0.24);
}

.buttonHero {
  background-color: #00b1bb;
  color: white;
  border: 2px solid white !important;
  cursor: pointer;
  z-index: 799;
  font-weight: bold;
}

.buttonHero:hover {
  background-color: white;
  color: #00b1bb;
  border: none;
}

.navMenu {
  color: black;
  font-size: 16px;
}

.navMenu:hover {
  color: #00b1bb !important;
  font-size: 19px !important;
}
